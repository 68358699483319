<template>
  <OnBoarding>
    <template>
      <CRow>
        <CCol>
          <OnBoardingHeader :title="$gettext('Login')"/>

          <CAlert v-if="isErrorVisible" show color="danger">{{ errorMessage }}</CAlert>

          <CInput
              ref="email"
              placeholder="E-mail"
              v-model="email"
              :label="$gettext('E-mail')"
              required
              autocomplete="username email"/>

          <InputPassword
              placeholder="Password"
              v-model="password"
              :label="$gettext('Password')"
              :required="true"
              :id="'loginPassword'"/>

          <CButton type="button" color="primary" block v-translate @click="login">Login</CButton>

          <div class="text-center info">
            <span v-translate>otherwise</span>
          </div>
          <SocialLogin/>
          <div class="text-center pt-3" v-if="allowRegister">
            <h5 class="font-weight-medium" style="font-size: 16px">
              <translate>Don't have an account?</translate>
              <CLink :to="{name: 'register'}" class="ml-2 font-weight-semi-bold">
                <span v-translate>Sing up</span>
                <CIcon class="ml-2" size="custom-size" :height="20" name="cip-log-out-04"/>
              </CLink>
            </h5>
          </div>
        </CCol>
      </CRow>
    </template>
  </OnBoarding>
</template>

<script>
import InputPassword from "@/components/InputPassword.vue";
import {mapActions} from "vuex";
import {useVuelidate} from "@vuelidate/core";
import {required, email} from "@vuelidate/validators";
import SocialLogin from "@/domain/login/components/SocialLogin.vue";
import {methods} from "@/domain/login/mixin";
import OnBoarding from "@/domain/login/OnBoarding.vue";
import OnBoardingHeader from "@/domain/login/components/OnBoardingHeader.vue";

export default {
  name: 'Login',
  components: {OnBoardingHeader, OnBoarding, SocialLogin, InputPassword},
  props: {
    allowRegister: {
      type: Boolean,
      default: true
    }
  },
  async mounted() {
    await this.checkQueryParams()
    this.$refs.email.$el.children[0].focus()
  },
  data() {
    return {
      loading: false,
      email: null,
      password: null,
      submitted: false,
      error: null,
      inValidation: false
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  validations() {
    return {
      email: {required, email},
      password: {required}
    }
  },
  computed: {
    isEmailValid() {
      if (!this.email || this.v$.email.$invalid)
        return false
      return true
    },
    isPasswordValid() {
      if (!this.password || this.v$.password.$invalid)
        return false
      return true
    },
    isValid() {
      return !this.v$.$invalid
    },
    isErrorVisible() {
      if ((this.submitted && !this.isValid) || this.error)
        return true
      return false
    },
    errorMessage() {
      if (!this.isEmailValid)
        return 'Invalid E-mail'
      if (!this.isPasswordValid)
        return 'Missing password'
      if (this.error)
        return this.error
      return null
    }
  },
  methods: {
    ...methods,
    ...mapActions('login', [
      'authenticate',
      'getCurrentUser',
    ]),
    async login() {
      this.submitted = true
      this.error = null
      if (this.isValid) {
        try {
          await this.authenticate({
            email: this.email,
            password: this.password,
            remember: true,
            allowRegister: this.allowRegister
          })
          await this.afterLogin()
        } catch (e) {
          if (await this.manageLoginError(e, this.email)) return
          this.error = 'Login error'
        }
      }
    },
  }
}
</script>
